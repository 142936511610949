import { Ref, computed, getCurrentInstance } from "vue";
import { useOauthPermissionsInfo } from "./useOauthPermissionsInfo";
import { oauthRoles } from "@/authConfig";

export interface OauthRoles {
  isAdmin: Ref<boolean>;
  canReadSales: Ref<boolean>;
  canWriteSales: Ref<boolean>;
  canWriteStrategy: Ref<boolean>;
  canReadStatistic: Ref<boolean>;
  canWriteEstimateParameter: Ref<boolean>;
  canWriteSignalWord: Ref<boolean>;
  canWriteRvcImport: Ref<boolean>;
  canReadRvcImport: Ref<boolean>;
}

export function useOauthRoles(instance = getCurrentInstance()): OauthRoles {
  const oauthPermissions = useOauthPermissionsInfo(instance);

  const isAdmin = computed(() => oauthPermissions.hasAnyRole(oauthRoles.Admin));
  const canReadSales = computed(() =>
    oauthPermissions.hasAnyRole(oauthRoles.Admin, oauthRoles.Umsaetze_Read),
  );
  const canWriteSales = computed(() =>
    oauthPermissions.hasAnyRole(oauthRoles.Admin, oauthRoles.Umsaetze_ReadWrite),
  )
  const canWriteStrategy = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.Admin,
      oauthRoles.Strategie_ReadWrite,
    ),
  );
  const canReadStatistic = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.Admin,
      oauthRoles.Statitik_Read,
    ),
  );
  const canWriteEstimateParameter = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.Admin,
      oauthRoles.KalkulatorischerAnsatz_ReadWrite,
    ),
  );
  const canWriteSignalWord = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.Admin,
      oauthRoles.Signalwort_ReadWrite,
    ),
  );
  const canReadRvcImport = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.Admin,
      oauthRoles.RvcImport_Read,
    ),
  );
  const canWriteRvcImport = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.Admin,
      oauthRoles.RvcImport_ReadWrite,
    ),
  );

  return {
    isAdmin,
    canReadSales,
    canWriteSales,
    canWriteStrategy,
    canReadStatistic,
    canWriteEstimateParameter,
    canWriteSignalWord,
    canReadRvcImport,
    canWriteRvcImport,
  };
}
