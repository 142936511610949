import { App, Plugin, Ref, ref } from "vue";
import {
  AllgemeinApi,
  AllgemeinApiInterface,
  Configuration as BackendConfiguration,
  ConfigurationParameters,
  PrognoseApi,
  PrognoseApiInterface,
  RailiumApi,
  RailiumApiInterface,
  StrategieApi,
  StrategieApiInterface,
  StatistikApi,
  StatistikApiInterface,
  SystemApi,
  SystemApiInterface,
  RvcApi,
  RvcApiInterface
} from "@apis/backend";
import { AccessToken, isValidAccessToken } from "./apiHelpers";
import { ApiMiddleware } from "./ApiMiddleware";
import {
  BaseApis,
  setupCallWhenTokenInitialized,
} from "@/plugins/baseApiPlugin";
import {
  getFakeAllgemeinApi,
  getFakePrognoseApi,
  getFakeRailiumApi,
  getFakeStrategieApi,
  getFakeSystemApi,
  getFakeRvcApi
} from "@/plugins/backendApiFake";
import { delegate } from "@/utils/fake";

export interface BackendApis extends BaseApis {
  system: SystemApiInterface;
  allgemein: AllgemeinApiInterface;
  railium: RailiumApiInterface;
  prognose: PrognoseApiInterface;
  strategie: StrategieApiInterface;
  statistik: StatistikApiInterface;
  rvc: RvcApiInterface;
  callWhenTokenInitialized: (toCall: () => void | (() => void)[]) => void;
}

export const apiPlugin: Plugin = {
  install: (app: App) => {
    app.config.globalProperties.$backendApiAccessToken = ref<AccessToken>();
    app.config.globalProperties.$backendApiAccessTokenLock = ref(false);

    const accessTokenToHeader: (at?: AccessToken) => string = (at) => {
      if (isValidAccessToken(at)) {
        return "Bearer " + at!.accessToken;
      } else {
        throw new Error("BackendApi: Missing access token");
      }
    };

    const configBase: ConfigurationParameters = {
      middleware: [new ApiMiddleware(app.config.globalProperties.$language)],
    };
    const backendConfig = new BackendConfiguration({
      ...configBase,
      basePath: "/api/backend",
      accessToken: () =>
        accessTokenToHeader(
          app.config.globalProperties.$backendApiAccessToken.value,
        ),
    });

    const isTokenInitialized = ref(false);

    app.config.globalProperties.$backendApis = {
      system: delegate(getFakeSystemApi(), new SystemApi(backendConfig)),
      allgemein: delegate(
        getFakeAllgemeinApi(),
        new AllgemeinApi(backendConfig),
      ),
      railium: delegate(getFakeRailiumApi(), new RailiumApi(backendConfig)),
      prognose: delegate(getFakePrognoseApi(), new PrognoseApi(backendConfig)),
      strategie: delegate(
        getFakeStrategieApi(),
        new StrategieApi(backendConfig),
      ),
      statistik: new StatistikApi(backendConfig),
      rvc: delegate(getFakeRvcApi(), new RvcApi(backendConfig)),
      isTokenInitialized: isTokenInitialized,
      isTokenCurrentlyValid: ref(false),
      callWhenTokenInitialized:
        setupCallWhenTokenInitialized(isTokenInitialized),
    };
  },
};

declare module "vue" {
  interface ComponentCustomProperties {
    $backendApiAccessToken: Ref<AccessToken | undefined>;
    $backendApis: BackendApis;
    $backendApiAccessTokenLock: Ref<boolean>;
  }
}
