/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RvcImportAntwort } from './RvcImportAntwort';
import {
    RvcImportAntwortFromJSON,
    RvcImportAntwortFromJSONTyped,
    RvcImportAntwortToJSON,
} from './RvcImportAntwort';

/**
 * 
 * @export
 * @interface RvcImporteAusschnitt
 */
export interface RvcImporteAusschnitt {
    /**
     * Anzahl der zurückgegebenen RVC-Importe.
     * @type {number}
     * @memberof RvcImporteAusschnitt
     */
    anzahlRvcImporte: number;
    /**
     * Anzahl der für den Filter zur Verfügung stehenden RVC-Importe.
     * @type {number}
     * @memberof RvcImporteAusschnitt
     */
    anzahlRvcImporteGesamt: number;
    /**
     * 
     * @type {Array<RvcImportAntwort>}
     * @memberof RvcImporteAusschnitt
     */
    rvcImporte: Array<RvcImportAntwort>;
}

/**
 * Check if a given object implements the RvcImporteAusschnitt interface.
 */
export function instanceOfRvcImporteAusschnitt(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "anzahlRvcImporte" in value;
    isInstance = isInstance && "anzahlRvcImporteGesamt" in value;
    isInstance = isInstance && "rvcImporte" in value;

    return isInstance;
}

export function RvcImporteAusschnittFromJSON(json: any): RvcImporteAusschnitt {
    return RvcImporteAusschnittFromJSONTyped(json, false);
}

export function RvcImporteAusschnittFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvcImporteAusschnitt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anzahlRvcImporte': json['anzahlRvcImporte'],
        'anzahlRvcImporteGesamt': json['anzahlRvcImporteGesamt'],
        'rvcImporte': ((json['rvcImporte'] as Array<any>).map(RvcImportAntwortFromJSON)),
    };
}

export function RvcImporteAusschnittToJSON(value?: RvcImporteAusschnitt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anzahlRvcImporte': value.anzahlRvcImporte,
        'anzahlRvcImporteGesamt': value.anzahlRvcImporteGesamt,
        'rvcImporte': ((value.rvcImporte as Array<any>).map(RvcImportAntwortToJSON)),
    };
}

