export enum RouteNames {
  HOME = "HOME",
  UMSAETZE = "UMSAETZE",
  UMSATZ = "UMSATZ",
  STRATEGIE = "STRATEGIE",
  SALESFORECAST = "SALESFORECAST",
  SIGNALWORDS = "SIGNALWORDS",
  TAG_MANAGEMENT = "TAG_MANAGEMENT",
  FORECAST_PARAMETER_MANAGEMENT = "FORECAST_PARAMETER_MANAGEMENT",
  CALCULATION_TREE = "CALCULATION_TREE",
  RVC_IMPORT ="RVC_IMPORT"
}
