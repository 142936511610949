/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RvcImportAenderung
 */
export interface RvcImportAenderung {
    /**
     * 
     * @type {string}
     * @memberof RvcImportAenderung
     */
    vertragsNummer: string;
    /**
     * 
     * @type {string}
     * @memberof RvcImportAenderung
     */
    vertragsName: string;
    /**
     * 
     * @type {string}
     * @memberof RvcImportAenderung
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {number}
     * @memberof RvcImportAenderung
     */
    berichtsNummer: number;
    /**
     * 
     * @type {number}
     * @memberof RvcImportAenderung
     */
    summeNetto: number;
    /**
     * 
     * @type {number}
     * @memberof RvcImportAenderung
     */
    vorhergehendeSummeNetto?: number;
    /**
     * 
     * @type {number}
     * @memberof RvcImportAenderung
     */
    vorhergehendeImportId?: number;
    /**
     * 
     * @type {string}
     * @memberof RvcImportAenderung
     */
    vorhergehenderImportAm?: string;
}

/**
 * Check if a given object implements the RvcImportAenderung interface.
 */
export function instanceOfRvcImportAenderung(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vertragsNummer" in value;
    isInstance = isInstance && "vertragsName" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "berichtsNummer" in value;
    isInstance = isInstance && "summeNetto" in value;

    return isInstance;
}

export function RvcImportAenderungFromJSON(json: any): RvcImportAenderung {
    return RvcImportAenderungFromJSONTyped(json, false);
}

export function RvcImportAenderungFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvcImportAenderung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vertragsNummer': json['vertragsNummer'],
        'vertragsName': json['vertragsName'],
        'maschinenGruppe': json['maschinenGruppe'],
        'berichtsNummer': json['berichtsNummer'],
        'summeNetto': json['summeNetto'],
        'vorhergehendeSummeNetto': !exists(json, 'vorhergehendeSummeNetto') ? undefined : json['vorhergehendeSummeNetto'],
        'vorhergehendeImportId': !exists(json, 'vorhergehendeImportId') ? undefined : json['vorhergehendeImportId'],
        'vorhergehenderImportAm': !exists(json, 'vorhergehenderImportAm') ? undefined : json['vorhergehenderImportAm'],
    };
}

export function RvcImportAenderungToJSON(value?: RvcImportAenderung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vertragsNummer': value.vertragsNummer,
        'vertragsName': value.vertragsName,
        'maschinenGruppe': value.maschinenGruppe,
        'berichtsNummer': value.berichtsNummer,
        'summeNetto': value.summeNetto,
        'vorhergehendeSummeNetto': value.vorhergehendeSummeNetto,
        'vorhergehendeImportId': value.vorhergehendeImportId,
        'vorhergehenderImportAm': value.vorhergehenderImportAm,
    };
}

