/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RvcImport } from './RvcImport';
import {
    RvcImportFromJSON,
    RvcImportFromJSONTyped,
    RvcImportToJSON,
} from './RvcImport';
import type { RvcImportAenderung } from './RvcImportAenderung';
import {
    RvcImportAenderungFromJSON,
    RvcImportAenderungFromJSONTyped,
    RvcImportAenderungToJSON,
} from './RvcImportAenderung';

/**
 * 
 * @export
 * @interface RvcImportAntwort
 */
export interface RvcImportAntwort {
    /**
     * 
     * @type {Array<RvcImportAenderung>}
     * @memberof RvcImportAntwort
     */
    rvcImportAenderung: Array<RvcImportAenderung>;
    /**
     * 
     * @type {RvcImport}
     * @memberof RvcImportAntwort
     */
    rvcImport?: RvcImport;
    /**
     * Enthält einen Hinweistext zum Import (z.B. wenn eine in der RVC-Sicherung enthaltene Maschinengruppe in der Datenbank nicht angelegt ist).
     * @type {string}
     * @memberof RvcImportAntwort
     */
    rvcImportInformation?: string;
}

/**
 * Check if a given object implements the RvcImportAntwort interface.
 */
export function instanceOfRvcImportAntwort(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rvcImportAenderung" in value;

    return isInstance;
}

export function RvcImportAntwortFromJSON(json: any): RvcImportAntwort {
    return RvcImportAntwortFromJSONTyped(json, false);
}

export function RvcImportAntwortFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvcImportAntwort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rvcImportAenderung': ((json['rvcImportAenderung'] as Array<any>).map(RvcImportAenderungFromJSON)),
        'rvcImport': !exists(json, 'rvcImport') ? undefined : RvcImportFromJSON(json['rvcImport']),
        'rvcImportInformation': !exists(json, 'rvcImportInformation') ? undefined : json['rvcImportInformation'],
    };
}

export function RvcImportAntwortToJSON(value?: RvcImportAntwort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rvcImportAenderung': ((value.rvcImportAenderung as Array<any>).map(RvcImportAenderungToJSON)),
        'rvcImport': RvcImportToJSON(value.rvcImport),
        'rvcImportInformation': value.rvcImportInformation,
    };
}

