/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RvcImportAntwort,
  RvcImporteAusschnitt,
  RvcSicherungHochladenRequest,
} from '../models/index';
import {
    RvcImportAntwortFromJSON,
    RvcImportAntwortToJSON,
    RvcImporteAusschnittFromJSON,
    RvcImporteAusschnittToJSON,
    RvcSicherungHochladenRequestFromJSON,
    RvcSicherungHochladenRequestToJSON,
} from '../models/index';

export interface RvcImporteRequest {
    seite: number;
    anzahl: number;
    filter: string;
}

export interface RvcSicherungHerunterladenRequest {
    rvcImportId: number;
}

export interface RvcSicherungHochladenOperationRequest {
    rvcSicherungHochladenRequest: RvcSicherungHochladenRequest;
}

/**
 * RvcApi - interface
 * 
 * @export
 * @interface RvcApiInterface
 */
export interface RvcApiInterface {
    /**
     * 
     * @summary Gibt eine Liste von RVC-Importen für den angegebenen RSQL-Filter zurück.
     * @param {number} seite Seitennummer bei mehreren Seiten.
     * @param {number} anzahl Gibt die Anzahl der Elemente pro Seite an.
     * @param {string} filter Gibt den RSQL-Filter für die gewünschten Elemente an.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RvcApiInterface
     */
    rvcImporteRaw(requestParameters: RvcImporteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RvcImporteAusschnitt>>;

    /**
     * Gibt eine Liste von RVC-Importen für den angegebenen RSQL-Filter zurück.
     */
    rvcImporte(requestParameters: RvcImporteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RvcImporteAusschnitt>;

    /**
     * 
     * @summary Gibt einen DownloadUrl für einen RVC-Import zurück.
     * @param {number} rvcImportId Id vom RvcImport der dazugehörigen Sicherung.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RvcApiInterface
     */
    rvcSicherungHerunterladenRaw(requestParameters: RvcSicherungHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * Gibt einen DownloadUrl für einen RVC-Import zurück.
     */
    rvcSicherungHerunterladen(requestParameters: RvcSicherungHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * 
     * @summary RVC Sicherungen hochladen
     * @param {RvcSicherungHochladenRequest} rvcSicherungHochladenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RvcApiInterface
     */
    rvcSicherungHochladenRaw(requestParameters: RvcSicherungHochladenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RvcImportAntwort>>;

    /**
     * RVC Sicherungen hochladen
     */
    rvcSicherungHochladen(requestParameters: RvcSicherungHochladenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RvcImportAntwort>;

}

/**
 * 
 */
export class RvcApi extends runtime.BaseAPI implements RvcApiInterface {

    /**
     * Gibt eine Liste von RVC-Importen für den angegebenen RSQL-Filter zurück.
     */
    async rvcImporteRaw(requestParameters: RvcImporteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RvcImporteAusschnitt>> {
        if (requestParameters.seite === null || requestParameters.seite === undefined) {
            throw new runtime.RequiredError('seite','Required parameter requestParameters.seite was null or undefined when calling rvcImporte.');
        }

        if (requestParameters.anzahl === null || requestParameters.anzahl === undefined) {
            throw new runtime.RequiredError('anzahl','Required parameter requestParameters.anzahl was null or undefined when calling rvcImporte.');
        }

        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling rvcImporte.');
        }

        const queryParameters: any = {};

        if (requestParameters.seite !== undefined) {
            queryParameters['seite'] = requestParameters.seite;
        }

        if (requestParameters.anzahl !== undefined) {
            queryParameters['anzahl'] = requestParameters.anzahl;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/rvc`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RvcImporteAusschnittFromJSON(jsonValue));
    }

    /**
     * Gibt eine Liste von RVC-Importen für den angegebenen RSQL-Filter zurück.
     */
    async rvcImporte(requestParameters: RvcImporteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RvcImporteAusschnitt> {
        const response = await this.rvcImporteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt einen DownloadUrl für einen RVC-Import zurück.
     */
    async rvcSicherungHerunterladenRaw(requestParameters: RvcSicherungHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.rvcImportId === null || requestParameters.rvcImportId === undefined) {
            throw new runtime.RequiredError('rvcImportId','Required parameter requestParameters.rvcImportId was null or undefined when calling rvcSicherungHerunterladen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/rvc/{rvcImportId}`.replace(`{${"rvcImportId"}}`, encodeURIComponent(String(requestParameters.rvcImportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Gibt einen DownloadUrl für einen RVC-Import zurück.
     */
    async rvcSicherungHerunterladen(requestParameters: RvcSicherungHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.rvcSicherungHerunterladenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * RVC Sicherungen hochladen
     */
    async rvcSicherungHochladenRaw(requestParameters: RvcSicherungHochladenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RvcImportAntwort>> {
        if (requestParameters.rvcSicherungHochladenRequest === null || requestParameters.rvcSicherungHochladenRequest === undefined) {
            throw new runtime.RequiredError('rvcSicherungHochladenRequest','Required parameter requestParameters.rvcSicherungHochladenRequest was null or undefined when calling rvcSicherungHochladen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/rvc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RvcSicherungHochladenRequestToJSON(requestParameters.rvcSicherungHochladenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RvcImportAntwortFromJSON(jsonValue));
    }

    /**
     * RVC Sicherungen hochladen
     */
    async rvcSicherungHochladen(requestParameters: RvcSicherungHochladenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RvcImportAntwort> {
        const response = await this.rvcSicherungHochladenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
