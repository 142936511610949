/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RvcImport
 */
export interface RvcImport {
    /**
     * 
     * @type {number}
     * @memberof RvcImport
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RvcImport
     */
    jahr: number;
    /**
     * 
     * @type {string}
     * @memberof RvcImport
     */
    erstelltAm: string;
    /**
     * 
     * @type {string}
     * @memberof RvcImport
     */
    erstelltVon: string;
    /**
     * 
     * @type {string}
     * @memberof RvcImport
     */
    originalDateiname: string;
}

/**
 * Check if a given object implements the RvcImport interface.
 */
export function instanceOfRvcImport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "jahr" in value;
    isInstance = isInstance && "erstelltAm" in value;
    isInstance = isInstance && "erstelltVon" in value;
    isInstance = isInstance && "originalDateiname" in value;

    return isInstance;
}

export function RvcImportFromJSON(json: any): RvcImport {
    return RvcImportFromJSONTyped(json, false);
}

export function RvcImportFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvcImport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'jahr': json['jahr'],
        'erstelltAm': json['erstelltAm'],
        'erstelltVon': json['erstelltVon'],
        'originalDateiname': json['originalDateiname'],
    };
}

export function RvcImportToJSON(value?: RvcImport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'jahr': value.jahr,
        'erstelltAm': value.erstelltAm,
        'erstelltVon': value.erstelltVon,
        'originalDateiname': value.originalDateiname,
    };
}

